export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (err) => {
    logError(formatMessage(err), 'errorHandler');
  };

  nuxtApp.vueApp.config.warnHandler = (msg) => {
    logWarn(msg, 'warnHandler');
  };

  // Skip for now, already logged with app:error (?)
  // nuxtApp.hook('vue:error', (err) => {
  //   logDebug(formatMessage(err), 'vue:error');
  // });

  nuxtApp.hook('app:error', (err) => {
    logError(formatMessage(err), 'app:error');
  });

  function formatMessage(err: any): string {
    let result = err as string;
    if (err.statusCode && err.statusMessage) {
      result = `${err.statusCode} ${err.statusMessage}`;
    } else if (err.statusMessage) {
      result = err.statusMessage;
    } else if (err.message) {
      result = err.message;
    }
    return `${result} ${nuxtApp.payload.path}`;
  }
});
