import { default as _91_46_46_46slug_939Ab3j86MvCMeta } from "/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/[...slug].vue?macro=true";
import { default as formulierYkmyUxT4i1Meta } from "/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/formulier.vue?macro=true";
import { default as index5SXLJG2kHSMeta } from "/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/index.vue?macro=true";
import { default as login8tJvLYHoctMeta } from "/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/login.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_939Ab3j86MvCMeta?.name ?? "slug",
    path: _91_46_46_46slug_939Ab3j86MvCMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_939Ab3j86MvCMeta || {},
    alias: _91_46_46_46slug_939Ab3j86MvCMeta?.alias || [],
    redirect: _91_46_46_46slug_939Ab3j86MvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: formulierYkmyUxT4i1Meta?.name ?? "formulier",
    path: formulierYkmyUxT4i1Meta?.path ?? "/formulier",
    meta: formulierYkmyUxT4i1Meta || {},
    alias: formulierYkmyUxT4i1Meta?.alias || [],
    redirect: formulierYkmyUxT4i1Meta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/formulier.vue").then(m => m.default || m)
  },
  {
    name: index5SXLJG2kHSMeta?.name ?? "index",
    path: index5SXLJG2kHSMeta?.path ?? "/",
    meta: index5SXLJG2kHSMeta || {},
    alias: index5SXLJG2kHSMeta?.alias || [],
    redirect: index5SXLJG2kHSMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login8tJvLYHoctMeta?.name ?? "login",
    path: login8tJvLYHoctMeta?.path ?? "/login",
    meta: login8tJvLYHoctMeta || {},
    alias: login8tJvLYHoctMeta?.alias || [],
    redirect: login8tJvLYHoctMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/Rx.Front_Frontend_f_main/pages/login.vue").then(m => m.default || m)
  }
]