import { $fetch, FetchOptions } from 'ofetch';
import { defineNuxtPlugin } from '#app';
import FormsModule from '~~/repository/modules/forms';

/** ApiInstance interface provides us with good typing */
interface IApiInstance {
  forms: FormsModule;
}

export default defineNuxtPlugin((nuxtApp) => {
  const isDev = process.env.NODE_ENV !== 'production';

  if (process.server && isDev && nuxtApp.ssrContext?.event.node.req.headers.host !== 'localhost:3000') {
    logError(
      'Zorg dat dit project op localhost:3000 te bereiken is om SSR content uit de API te kunnen gebruiken.',
      '/plugins/api'
    );
  }
  const fetchOptions: FetchOptions = {
    baseURL: (process.server ? 'http://localhost:3000' : '') + '/api',
    headers: useRequestHeaders(),
  };

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions);

  /** an object containing all repositories we need to expose */
  const modules: IApiInstance = {
    forms: new FormsModule(apiFetcher),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
