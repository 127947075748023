import { $Fetch } from 'ofetch';

class HttpFactory {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  /**
   * method - GET, POST, PUT
   * URL
   **/
  async call<T>(method: string, url: string, data?: object, extras = {}): Promise<T> {
    const authcookie = useCookie('auth:token', {
      maxAge: 24 * 60 * 60,
      sameSite: true,
      secure: true,
    });
    const $res: T = await this.$fetch(url, {
      method,
      body: data,
      headers: authcookie ? { Authorization: 'Bearer ' + authcookie.value } : {},
      ...extras,
    }).catch((response) => {
      return Promise.reject(response.status);
    });
    return $res;
  }
}

export default HttpFactory;
