import chalk from 'chalk';
import stringify from 'stringify-json-object';

const isDev = process.env.NODE_ENV !== 'production';
const isServer = process.server;

export function logError(message: string, source?: string) {
  if (isServer) {
    serverError(message, source);
  } else if (isDev) {
    // eslint-disable-next-line no-console
    console.error(source, message);
  }
}

export function logWarn(message: string, source?: string) {
  if (isServer) {
    serverWarn(message, source);
  } else if (isDev) {
    // eslint-disable-next-line no-console
    console.warn(source, message);
  }
}

export function logInfo(message: string, source?: string) {
  if (isServer) {
    serverInfo(message, source);
  } else if (isDev) {
    // eslint-disable-next-line no-console
    console.info(source, message);
  }
}

export function logDebug(message: string, source?: string) {
  if (isServer && isDev) {
    serverDebug(message, source);
  } else if (isDev) {
    // eslint-disable-next-line no-console
    console.debug(source, message);
  }
}

const error = chalk.red;
const warn = chalk.hex('#FFA500');
const info = chalk.yellow;
const debug = chalk.green;

function serverLog(level: string, message: string, source?: string) {
  return stringify({ level, message, source }, false);
}

function serverError(message: string, source?: string) {
  // eslint-disable-next-line no-console
  console.error(error(serverLog('error', message, source)));
}

function serverWarn(message: string, source?: string) {
  // eslint-disable-next-line no-console
  console.warn(warn(serverLog('warn', message, source)));
}

function serverInfo(message: string, source?: string) {
  // eslint-disable-next-line no-console
  console.info(info(serverLog('info', message, source)));
}

function serverDebug(message: string, source?: string) {
  // !workaround for an optimization issue.
  // If these lines are combined 'npm run build' fails due to an optimization pass leaving behind broken javascript
  const log = serverLog('debug', message, source);
  // eslint-disable-next-line no-console
  console.debug(debug(log));
}
