// import { ICreateAccountInput, ICreateAccountResponse, ILoginInput, ILoginResponse } from 'types';
import HttpFactory from '../factory';
import { IFormInfo, IFormSchema } from '~/types';

class FormsModule extends HttpFactory {
  private RESOURCE = '/forms';

  async get(friendlyName = ''): Promise<IFormInfo[]> {
    return await this.call<IFormInfo[]>('GET', `${this.RESOURCE}/` + friendlyName);
  }

  async schema(formDefinitionUuid = ''): Promise<IFormSchema> {
    return await this.call<IFormSchema>('GET', `${this.RESOURCE}/schema/` + formDefinitionUuid);
  }

  async submit(formDefinitionUuid = '', data: FormData): Promise<IFormSchema> {
    return await this.call<IFormSchema>('POST', `${this.RESOURCE}/submit/` + formDefinitionUuid, data);
  }
}

export default FormsModule;
