<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: `bg-slate-100 dark:bg-slate-800`,
  },
});
</script>
